/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import Layout from '../../components/layout';
import Navbar from '../../components/navbar/navbar';
import MobileNavbar from '../../components/mobile-navbar/mobile-navbar';
import {
  LayoutUI,
  DisplayText,
  Header,
  HeroImage,
  Stack,
  TextStyle
} from '../../ui-kit';

import Signature from '../../assets/images/our-story/signature.svg';
import HeaderImage from '../../assets/images/our-story/header.jpg';
import * as styles from './OurStory.module.scss';

const OurStory = () => (
  <Layout>
    <Navbar color="white" />
    <MobileNavbar color="white" />
    <div>
      <HeroImage size="medium" image={HeaderImage} overlay="medium">
        <LayoutUI narrow>
          <Stack alignment="center" distribution="center">
            <TextStyle color="white" center>
              <DisplayText size="small">
                The Story of <br />
                Project Happiness
              </DisplayText>
            </TextStyle>
          </Stack>
        </LayoutUI>
      </HeroImage>

      <LayoutUI narrow>
        <Stack vertical spacing="extraLoose" alignment="center">
          <div className={styles.StoryHeader}>
            <Header>Our Story</Header>
          </div>
          <div className={styles.Story}>
            <p>
              Sitting across the kitchen table, my teenager whispered, "I want
              to be happy but I don’t know...how." As the situation became more
              intense in school and at home, signs of depression became more
              evident and I didn’t know what to do. It rocked me to the core; I
              ached for a way to help. I asked myself, "Where were the
              resources, how many more were suffering, and why are we not
              talking about this?"
            </p>

            <p>
              This was the beginning of my journey, and the catalyst for
              starting my pro-purpose, non-profit, Project Happiness. Though my
              inspiration was personal, I soon found out the issue at hand was
              global. My vision in finding resources to help my own child soon
              expanded to the dream of World Happiness Domination!
            </p>

            <p>
              But, where would I start? Looking for answers, I produced an
              <a
                href="https://shop.projecthappiness.org/collections/all-products/products/project-happiness-dvd"
                className={styles.StoryLink}
              >
                {' '}
                award-winning documentary{' '}
              </a>
              on happiness featuring George Lucas, Richard Gere, Neuroscientist
              Richard Davidson, and the Dalai Lama.
            </p>

            <blockquote className={styles.Blockquote}>
              "I ached for a way to help. As the situation became more intense,
              I didn't know what to do, and it rocked me to the core. This was
              the beginning of my journey"
            </blockquote>

            <p>
              Next, I
              <a
                href="https://shop.projecthappiness.org/collections/all-products/products/project-happiness-handbook"
                className={styles.StoryLink}
              >
                {' '}
                co-authored a book
              </a>
              , now an Amazon bestseller, which included all the world-class
              resources, pulled from science, I wished had been available when I
              was seeking them. The book eventually generated requests pouring
              in to create a social emotional learning curriculum, an online
              course for adults, a global happiness book club, and our social
              media to reach even more people. Now in over 120 countries and
              counting, our programs have allowed us to connect with over 3
              million people. Our community is amazing!
            </p>

            <p>
              The good news is that my child is doing great, and we have learned
              through this journey that happiness is not just a state; happiness
              is a skill set that anyone can learn. The World Health
              Organization has officially stated that depression is the leading
              cause of global mental and physical disability, affecting 1 in 20
              people.
            </p>

            <p>
              <strong>Our goal is to impact 10,000,000 lives.</strong> Kindness,
              mindfulness, and all the other tools we can use are not just
              niceties – they are key to bringing out the best in us, and our
              society.
            </p>

            <p>
              The science is strong and the need is compelling. It’s exciting
              that we can now give those who need it most the tools to take
              charge of their own happiness, to become more aware, emotionally
              resilient, and develop the skills to navigate whatever life
              presents. Happiness, like all emotions, is contagious; for
              ourselves and generations to come, let’s create a world where
              everyone can thrive.
            </p>
          </div>
        </Stack>
        <img src={Signature} alt="- Randy" className={styles.Signature} />
      </LayoutUI>
    </div>
  </Layout>
);

export default OurStory;
